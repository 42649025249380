/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { SacCotizacionService } from 'app/services/acuerdos-comerciales/sacCotizacion.service';
import { SacCotizacionConceptoService } from 'app/services/acuerdos-comerciales/sacCotizacionConcepto.service';
import { SacCotizacionOperativaService } from 'app/services/acuerdos-comerciales/sacCotizacionOperativa.service';
import { SacCotizacionTipoOperadorService } from 'app/services/acuerdos-comerciales/sacCotizacionTipoOperador.service';
import { ScaRoutingService } from 'app/services/carga/scaRouting.service';
import { SacTipoEstadoCotizacionService } from 'app/services/maestros-sync/acuerdos-comerciales/sacTipoEstadoCotizacion.service';
import { SciSistemaService } from 'app/services/maestros-sync/config/sciSistema.service';
import { firstValueFrom, forkJoin, Observable } from 'rxjs';
import { MessageUtilService } from 'sumax-erp-schematics';
import { SacCotizacionConcepto } from '~models/acuerdos-comerciales';
import { SacCotizacion } from '~models/acuerdos-comerciales/SacCotizacion';
import { SacCotizacionOperativa } from '~models/acuerdos-comerciales/sacCotizacionOperativa';
import { SciSistema } from '~models/config';
import { SacCotizacionTipoOperador } from '~models/maestros-sync/maestros/SacCotizacionTipoOperador';
import { EnumSistema } from '~shared/enums/EnumSistema';
import { DateUtil } from '~shared/utils/DateUtil';
import { hideLoading, showLoading } from '~shared/utils/LoadingUtil';
import { MessageUtilService as messageCustom } from '~shared/services/message-util.service';
import { SacCotizacionEstadoService } from 'app/services/acuerdos-comerciales/sacCotizacionEstado.service';
import { SacCotizacionEstado } from '~models/acuerdos-comerciales/SacCotizacionEstado';
import { EnumEstadoCotizacion } from '~shared/enums/EnumEstadoCotizacion';

@Injectable({
	providedIn: 'root',
})
export class CotizacionService {
	constructor(
		private readonly sacCotizacionService: SacCotizacionService,
		private readonly sacTipoEstadoCotizacionService: SacTipoEstadoCotizacionService,
		private readonly scaRoutingService: ScaRoutingService,
		private readonly messageUtilService: MessageUtilService,
		private readonly sacCotizacionOperativaService: SacCotizacionOperativaService,
		private readonly sacCotizacionConceptoService: SacCotizacionConceptoService,
		private readonly sacCotizacionTipoOperadorService: SacCotizacionTipoOperadorService,
		private readonly sacCotizacionEstadoService: SacCotizacionEstadoService,
		private readonly sciSistemaService: SciSistemaService
	) {}

	async aperturarScaRouting(idCotizacion: number): Promise<void> {
		showLoading('Generando Routing');
		const obser: Observable<[SacCotizacion, SacCotizacionOperativa, SacCotizacionConcepto[], SciSistema]> = forkJoin([
			this.sacCotizacionService.findById(idCotizacion),
			this.sacCotizacionOperativaService.findById(idCotizacion),
			this.sacCotizacionConceptoService.findAllByIdCotizacion(idCotizacion),
			this.sciSistemaService.findByCodigo(EnumSistema.SIS_CARGA),
		]);

		obser.subscribe(async ([coti, cotiOper, cotiConceptos, sciSistema]) => {
			if (!coti) {
				hideLoading();
				return;
			}
			// if (coti.idTipoEstadoCotizacion) {
			// 	const estado = await firstValueFrom(this.sacTipoEstadoCotizacionService.findById(coti.idTipoEstadoCotizacion));
			// 	if (estado.codigo != EnumEstadoCotizacion.COMPLETADA) {
			// 		this.messageUtilService.getAlertWarning('La cotización debe tener el estado COMPLETADO.');
			// 		hideLoading();
			// 		return;
			// 	}
			// }
			if (cotiConceptos.length === 0) {
				hideLoading();
				const messageToast = new messageCustom();
				messageToast.getAlertWarning('Verifique su cotización y vuelva a intentarlo.', 'No se encontraron servicios registrados');
				return;
			}

			coti.sacCotizacionConceptos = [...cotiConceptos];

			const ro = {
				idTipoRouting: 1,
				idDivision: 1,
				scaOrdenCarga: {
					idOrdenCarga: null,
					idTipoRouting: 1,
					idViaTransporte: coti.idViaTransporte,
					idTipoManifiesto: coti.idTipoManifiesto,
					ctrlDescargaDirecta: false,
					ano: DateUtil.getYear(),
					fchOrden: new Date(),
					// nroDocumentoTransporte: 'NDODCTO-89456',
					// idUbicacionComercialEmision: 1,
					// fchEmision: '2024-08-28',
					// nroReserva: null,
					// fchReserva: null,
					idAgenteExtranjero: cotiOper?.idAgenteExtranjero,
					idUbicacionComercialOrigen: coti.idUbicacionComercialOrigen,
					idUbicacionComercialEmbarque: coti.idUbicacionComercialEmbarque,
					idUbicacionComercialLlegada: coti.idUbicacionComercialLlegada,
					idTransportista: coti.idTransportista,
					idAgenteCarga: cotiOper?.idAgenteCarga,
					idAgenteMaritimo: cotiOper?.idAgenteMaritimo,
					idTipoEnvio: coti.idTipoEnvio,
					scaNaveSalida: cotiOper?.idNaveSalida
						? {
								idNave: cotiOper?.idNaveSalida,
						  }
						: null,
					nroViajeSalida: cotiOper?.nroViajeSalida,
					// scaNaveLlegada: {
					// 	idNave: 106,
					// },
					// nroViajeLlegada: '',
					scaContratoEntidad: coti.idContratoEntidad ? { idContratoEntidad: coti.idContratoEntidad } : null,
					// idDepositoLlegadaVacio: 430,
					ctrlDireccionamiento: false,
					idDepositoFinal: cotiOper?.idDepositoFinal,
					// encargadoDireccionamiento: 'A',
					// fchDireccionamiento: null,
					idEntidadLocal: cotiOper?.idEntidadLocalDepositoFinal,
					// idTerminalPortuario: 26,
					// nroOrdenCarga: '',
					fchEstimadaLlegadaDestino: coti.fchEstimadaLlegadaDestino,
					// fchEstimadaLlegadaOrigen: '2024-08-29T12:16',
					fchEstimadaEmbarque: coti.fchEstimadaEmbarque,
					// fchTerminoEmbarque: '2024-08-31T12:16',
					// fchEmbarque: '2024-08-31T12:16',
					// fchLlegada: '2024-09-03T12:16',
					idOficina: coti.idOficina,
				},
				idTipoEstadoRouting: 1,
				ano: DateUtil.getYear(),
				fchRouting: new Date(),
				idCliente: coti.idCliente,
				idEntidadContactoCliente: coti.idEntidadContactoCliente,
				referenciaCliente: coti.referenciaCliente,
				idEjecutivoVendedor: coti.idEjecutivoVendedor,
				idEjecutivoCustomer: cotiOper?.idEjecutivoCustomer,
				idEjecutivoOperativo: cotiOper?.idEjecutivoOperativo,
				// nroReserva: null,
				// fchReserva: '2024-08-28',
				tipoConocimiento: 'H',
				// nroDocumentoTransporte: 'NRODCTO-123445213',
				// fchEmision: '2024-08-28',
				// idUbicacionComercialEmision: 1,
				idEntidadEmbarcador: cotiOper?.idEntidadEmbarcador,
				idEntidadContactoEmbarcador: cotiOper?.idEntidadContactoEmbarcador,
				idEntidadConsignatario: cotiOper?.idEntidadConsignatario,
				idEntidadContactoConsignatario: cotiOper?.idEntidadContactoConsignatario,
				// idEntidadNotificante: 1690,
				// idEntidadContactoNotificante: 173,
				endoseToOrder: false,
				// idTipoDestinacion: 1,
				idUbicacionComercialDestino: coti.idUbicacionComercialDestino,
				idIncoterm: coti.idIncoterm,
				idCompaniaSeguro: cotiOper?.idCompaniaSeguro,
				// valorDeclaradoMercancia: null,
				// fchCutOff: null,
				// fchEntregaOrigen: '2024-08-29T12:16',
				// fchEntregaDestino: '2024-09-07T12:16',
				// fchDescarga: '2024-08-30T12:16',
				idTipoMovimientoContenedor: coti.idTipoMovimientoContenedor,
				idPaisMercaderia: coti.tbUbicacionComercialOrigen?.tbPais?.idPais,
				// idTipoTransporte: 2,
				scaRoutingContenedores: this._getScaRoutingContenedores(coti),
				scaRoutingDetalles: this._getScaRoutingDetalles(coti),
				scaRoutingCondicionServicios: this._getScaRoutingCondicionServicios(coti),
				scaRoutingObservaciones: this._getScaRoutingObservaciones(coti),
				scaRoutingConceptos: this._getScaRoutingConceptos(coti),
				// scaRoutingDocumentos: ScaRoutingDocumento[];
				// scaRoutingIncidencias: ScaRoutingIncidencia[];
				// scaRoutingVehiculos: ScaRoutingVehiculoDto[];
			};
			// console.log(coti, ro);

			const res = await firstValueFrom(this.scaRoutingService.openingRouting(ro));
			if (res) {
				const sacCotizacionTipoOperadorUpd: SacCotizacionTipoOperador = {
					sacCotizacion: coti,
					idTipoOperador: 3,
					idSistema: sciSistema.idSistema,
					idOrden: res[1].idRouting,
					nroReferencia: res[1]?.nroRouting,
				};
				/** Update despues de la apertura */
				const resApertura = await firstValueFrom(this.sacCotizacionTipoOperadorService.updateAfterApertura(sacCotizacionTipoOperadorUpd));
				if (!resApertura) {
					await this.messageUtilService.getAlertWarning('La entidad no fue actualizada después de la Apertura');
				}
				/** Update estado para la cotización */
				const estado = await firstValueFrom(this.sacTipoEstadoCotizacionService.findByCodigo(EnumEstadoCotizacion.COMPLETADA));
				if (estado) {
					const cotiEstado: SacCotizacionEstado = {
						sacCotizacion: coti,
						idTipoEstadoCotizacion: estado.idTipoEstadoCotizacion,
					};
					const resEstados: SacCotizacionEstado[] = await firstValueFrom(this.sacCotizacionEstadoService.updateEstadoForSacCotizacion(cotiEstado));
					if (!resEstados) {
						await this.messageUtilService.getAlertWarning('La entidad no fue actualizada después de la Apertura');
					}
				}
				await this.messageUtilService.getMessageSuccess('Registrado', `El Routing ${String(res[1]?.nroRouting)} se ha registrado correctamente.`);
			}
			hideLoading();
		});
	}

	private _getScaRoutingContenedores(coti: SacCotizacion): any[] {
		let scaRoutingContenedores: any = [];
		coti.sacCotizacionContenedores?.forEach((c) => {
			scaRoutingContenedores.push({
				idTipoContenedor: c.idTipoContenedor,
				idSubtipoContenedor: c.idSubtipoContenedor,
				diasSobrestadia: c.diasSobrestadia,
				diasAlmacenaje: c.diasAlmacenaje,
			});
		});
		return scaRoutingContenedores;
	}
	private _getScaRoutingDetalles(coti: SacCotizacion): any[] {
		let scaRoutingDetalles: any = [];
		coti.sacCotizacionDetalles?.forEach((d) => {
			let sacCotizacionDetalleBultos: any = [];

			d.sacCotizacionDetalleBultos?.forEach((b) => {
				sacCotizacionDetalleBultos?.push({
					cantidadBultos: b.cantidadBulto,
					idUnidadMedida: b.idUnidadMedida,
					largoBulto: b.largoBulto,
					anchoBulto: b.anchoBulto,
					altoBulto: b.altoBulto,
					pesoBulto: b.pesoBulto,
					volumenBulto: b.volumenBulto,
					pesoVolumetricoBulto: b.pesoVolumetricoBulto,
					peso: b.peso,
					volumen: b.volumen,
					pesoVolumetrico: b.pesoVolumetrico,
				});
			});

			scaRoutingDetalles?.push({
				cantidadBultos: d?.cantidadBultos,
				idTipoBulto: d?.idTipoBulto,
				pesoBruto: d?.pesoBruto,
				idUnidadMedidaPeso: d?.idUnidadMedidaPeso,
				volumen: d?.volumen,
				idUnidadMedidaVolumen: d?.idUnidadMedidaVolumen,
				resumenMercancia: d?.resumenMercancia,
				idNaturalezaCarga: d?.idNaturalezaCarga,
				idMercanciaPeligrosa: d?.idMercanciaPeligrosa,
				idReguladorMercanciaPeligrosa: d?.idReguladorMercanciaPeligrosa,
				idUnidadTemperatura: d?.idUnidadTemperatura,
				temperaturaMaximaInflamable: d?.temperaturaMaximaInflamable,
				nroPaginaClasificacion: d?.nroPaginaClasificacion,
				scaRoutingDetalleBultos: sacCotizacionDetalleBultos,
			});
		});
		return scaRoutingDetalles;
	}
	private _getScaRoutingCondicionServicios(coti: SacCotizacion): any[] {
		let scaRoutingCondicionServicios: any = [];
		coti.sacCotizacionCondicionServicios?.forEach((c) => {
			scaRoutingCondicionServicios.push({
				tbCondicionServicio: {
					idCondicionServicio: c.tbCondicionServicio?.idCondicionServicio,
				},
			});
		});
		return scaRoutingCondicionServicios;
	}
	private _getScaRoutingObservaciones(coti: SacCotizacion): any[] {
		let scaRoutingObservaciones: any = [];
		coti.sacCotizacionObservaciones?.forEach((o) => {
			scaRoutingObservaciones.push({
				idTipoObservacion: o.idTipoObservacion,
				observacion: o.observacion,
			});
		});
		return scaRoutingObservaciones;
	}
	private _getScaRoutingConceptos(coti: SacCotizacion): any[] {
		let scaRoutingConceptos: any = [];
		coti.sacCotizacionConceptos?.forEach((o) => {
			scaRoutingConceptos.push({
				idGrupoConcepto: o.idGrupoConcepto,
				idConcepto: o.idConcepto,
				descripcion: o.descripcion,
				idTipoAfectacion: o.idTipoAfectacion,
				porcentajeImpuesto: o.porcentajeImpuesto,
				idUnidadMedida: o.idUnidadMedida,
				idMoneda: o.idMoneda,
				cantidad: o.cantidad,
				precioUnitarioCompra: o.precioUnitarioCompra,
				precioUnitarioVenta: o.precioUnitarioVenta,
				ctrlImpuestoCompra: o.ctrlImpuestoCompra,
				ctrlImpuestoVenta: o.ctrlImpuestoVenta,
				importeCompra: o.importeCompra,
				importeVenta: o.importeVenta,
				importeUtilidad: o.importeUtilidad,
				tipoCambioCompra: o.tipoCambioCompra,
				tipoCambioVenta: o.tipoCambioVenta,
				factorConversionCompra: o.factorConversionCompra,
				factorConversionVenta: o.factorConversionVenta,
				idModalidadPagoCompra: o.idModalidadPagoCompra,
				idModalidadPagoVenta: o.idModalidadPagoVenta,
				idEntidadCompra: o.idEntidadCompra,
				razonSocialEntidadCompra: o.razonSocialEntidadCompra,
				idEntidadVenta: o.idEntidadVenta,
				razonSocialEntidadVenta: o.razonSocialEntidadVenta,
				observacionCompra: o.observacionCompra,
				observacionVenta: o.observacionVenta,
				ctrlReembolsable: o.ctrlReembolsable,
			});
		});
		return scaRoutingConceptos;
	}
}
